<template>
  <div>
    <div v-if="orderStatus === 'PENDING_ACCEPTANCE'" id="modal">
      <div id="order-pending">
        <h2>{{ serviceType == 'REQUESTS' ? labels.order.requestSent[language] : labels.order.orderSent[language] }}</h2>
        <h4>{{ isServiceWithDelayedResponse ? labels.order.pleaseWaitForStoreToAcceptToReviewTheRequest[language] : serviceType == 'REQUESTS' ? labels.order.pleaseWaitRequestToBeAccepted[language] : labels.order.pleaseWaitOrderToBeAccepted[language] }}</h4>
      </div>
      <div id="waiting-div">
        <img id="waiting-gif" src="../assets/clock-transparent.gif" alt />
      </div>
      <div id="warning">
        <h5 class="footer-message">{{ labels.order.doNotClose[language] }}</h5>
      </div>
    </div>
    <div v-else-if="orderStatus === 'ACCEPTED'" id="accepted-modal">
      <div
        v-if="(tableType == 'SELF_SERVICE_POS' || tableType == 'QR_SELF_SERVICE') && deliveryType!='RENTALS'"
        id="self-service-order-accepted"
      >
        <h2>
          {{ labels.order.dailyNumber[language] }}
          <span id="order-daily-number">{{orderDailyNumber}}</span>
        </h2>
      </div>
      <div v-else id="order-accepted">
        <h2
          :class="{ smallerAcceptedHeader: isServiceWithDelayedResponse }"
        >{{ acceptedHeaderMessage }}</h2>
      </div>
      <div v-if="isServiceWithDelayedResponse" id="congratulations-div">
        <img id="congratulations-icon" src="../assets/order-in-progress.png" alt />
      </div>
      <div v-else id="congratulations-div">
        <img
          v-if="customAcceptedOrderImage"
          id="congratulations-icon"
          :src="`${server_url}${customAcceptedOrderImage}`"
          alt
        />
        <img v-else id="congratulations-icon" src="../assets/accepted.png" alt />
      </div>
      <div id="accepted-order-footer">
        <h5 class="footer-message" :class="{ bolderFooter: isServiceWithDelayedResponse }">
          <font-awesome-icon
            v-if="isServiceWithDelayedResponse"
            id="exclamation-sign"
            icon="exclamation-circle"
          />
          {{ acceptedFooterMessage }}
        </h5>
      </div>
      <div style="margin-bottom: 8px;">
        <Button
          v-if="tableType == 'SELF_SERVICE_POS'"
          id="new-order-button"
          @click="onCloseClick"
        >{{ labels.order.newOrder[language] }}</Button>
      </div>

      <div v-if="!hasUserOptedInForNewsletter" id="personal-data-info">
        <font-awesome-icon @click="toggleInfo" id="info-icon" icon="info-circle"></font-awesome-icon>
        <div v-if="showInfo" id="info-message-container">
          <p>{{ labels.order.newsletter[language] }}</p>
          <div id="unsub-button-and-result">
            <Button
              @click="unsubscribeFromNewsletter"
              :isWhiteButton="true"
              :loading="loadingUnsub"
              :disabled="unsubSuccess"
              id="unsub-newsletter"
            >{{ labels.order.unsubscribe[language] }}</Button>
            <p
              v-if="unsubSuccess == false"
              id="unsub-error"
            >{{ labels.order.failedUnsub[language] }}</p>
            <p v-if="unsubSuccess" id="unsub-success">{{ labels.order.successfulUnsub[language] }}</p>
          </div>
        </div>
      </div>
      <div @click="onCloseClick" id="close-modal">
        <font-awesome-icon id="times" icon="times" />
      </div>
    </div>
    <div v-else-if="orderStatus.includes('ERROR')" id="modal">
      <div
        id="order-error"
        v-if="orderErrorMessage != null && orderErrorMessage != undefined && orderErrorMessage != '' "
      >
        <h2>{{ orderErrorMessage[language] }}</h2>
      </div>
      <div v-else>
        <h2>{{ labels.order.somethingWentWrong[language] }}</h2>
        <h4>{{ labels.order.checkConnection[language] }}</h4>
      </div>
      <div id="rejected-div">
        <img
          v-if="customErrorOrderImage"
          id="rejected-icon"
          :src="`${server_url}${customErrorOrderImage}`"
          alt
        />
        <img v-else id="rejected-icon" src="../assets/order-error.png" alt />
      </div>
      <div id="help-coming">
        <h5
          class="footer-message"
          v-if="isDelivery"
        >{{ labels.order.staffWillCommunicate[language] }}</h5>
        <h5 class="footer-message" v-else>{{ labels.order.tryAgain[language] }}</h5>
      </div>
      <div @click="onCloseClick" id="close-modal">
        <font-awesome-icon id="times" icon="times" />
      </div>
    </div>
    <div v-else-if="orderStatus === 'REJECTED'" id="modal">
      <div id="order-rejected">
        <h2>{{ serviceType == 'REQUESTS' || isServiceWithDelayedResponse ? labels.order.requestRejected[language]: labels.order.orderRejected[language] }}</h2>
        <h4>{{ labels.order.possibleUnavailability[language] }}</h4>
      </div>
      <div id="rejected-div">
        <img
          v-if="customRejectOrderImage"
          id="rejected-icon"
          :src="`${server_url}${customRejectOrderImage}`"
          alt
        />
        <img v-else id="rejected-icon" src="../assets/order-error.png" alt />
      </div>
      <div id="help-coming">
        <h5
          class="footer-message"
          v-if="isDelivery"
        >{{ labels.order.staffWillCommunicate[language] }}</h5>
        <h5 class="footer-message" v-else>{{ labels.order.tryAgain[language] }}</h5>
      </div>
      <div @click="onCloseClick" id="close-modal">
        <font-awesome-icon id="times" icon="times" />
      </div>
    </div>
    <div v-else-if="orderStatus === 'PAYMENT_FAILED'" id="modal">
      <div id="order-rejected">
        <h2>{{ labels.order.paymentFailed[language] }}</h2>
      </div>
      <div id="rejected-div">
        <img
          v-if="customPaymentFailedOrderImage"
          id="rejected-icon"
          :src="`${server_url}${customPaymentFailedOrderImage}`"
          alt
        />
        <img v-else id="rejected-icon" src="../assets/order-error.png" alt />
      </div>
      <div id="help-coming">
        <h5 class="footer-message">{{ labels.order.tryAgain[language] }}</h5>
      </div>
      <div @click="onCloseClick" id="close-modal">
        <font-awesome-icon id="times" icon="times" />
      </div>
    </div>
    <div v-else-if="orderStatus === 'TIMED_OUT'" id="modal">
      <div id="order-rejected">
        <h2>{{ labels.order.tooMuchLoad[language] }}</h2>
        <h4>{{ labels.order.timedOut[language] }}</h4>
      </div>
      <div id="rejected-div">
        <img
          v-if="customTimedOutOrderImage"
          id="rejected-icon"
          :src="`${server_url}${customTimedOutOrderImage}`"
          alt
        />
        <img v-else id="rejected-icon" src="../assets/timed-out.png" alt />
      </div>
      <div id="help-coming">
        <h5 class="footer-message">{{ labels.order.tryAgain[language] }}</h5>
      </div>
      <div @click="onCloseClick" id="close-modal">
        <font-awesome-icon id="times" icon="times" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button"

import UserApi from "../api/user"

export default {
  name: "OrderModal",
  components: {
    Button,
  },
  props: ["successImage", "failImage", "deliveryType", "tableType", "isEmailSentToUserAtOrderExecution", "isSmsSentToUserAtOrderExecution", "redirectBackTo", "numberOfServices"],
  watch: {
    orderStatus(newVal, oldVal) {
      if (this.tableType == "SELF_SERVICE_POS" && newVal && newVal == 'ACCEPTED') {
        clearTimeout(this.redirectTimeout);
        this.redirectTimeout = setTimeout(() => {
          let routeName = this.$route.name;
          if (routeName == 'cart') {
            this.$store.dispatch("closeOrderModal");
            this.$store.dispatch("setOrderId", undefined);
            this.$store.dispatch("setOrderDailyNumber", undefined);
            this.showInfo = false

            this.$store.dispatch("setOrderStatus", "");
            this.$store.dispatch("emptyCart");
            this.$store.dispatch("setOrderComment", "");
            this.unsubSuccess = undefined
            this.$router.push({
              name: "menu",
              params: { tableId: this.$router.currentRoute.params.tableId },
            });
          }
        }, 22000);
      }
    },
  },
  computed: {

    serviceType() {
      return this.$store.getters.getServiceType;
    },

    isServiceWithDelayedResponse() {
      return this.$store.getters.getServiceDelayedResponse;
    },

    orderStatus() {
      return this.$store.getters.getOrderStatus;
    },

    orderErrorMessage() {
      return this.$store.getters.getOrderErrorMessage;
    },

    orderScheduled() {
      return this.$store.getters.getOrderScheduled;
    },

    orderDailyNumber() {
      return this.$store.getters.getOrderDailyNumber;
    },

    isDelivery() {
      return this.$store.getters.getTableType == "DELIVERY" || this.$store.getters.getTableType == "DELIVERY_TABLE"
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    hasUserOptedInForNewsletter() {
      return this.$store.getters.hasUserOptedInForNewsletter
    },

    server_url() {
      return process.env.VUE_APP_SERVER_URL;
    },

    customAcceptedOrderImage() {
      return this.$store.getters.getCustomAcceptOrderImage
    },

    customRejectOrderImage() {
      return this.$store.getters.getCustomRejectOrderImage
    },

    customTimedOutOrderImage() {
      return this.$store.getters.getCustomTimedOutOrderImage
    },

    customErrorOrderImage() {
      return this.$store.getters.getCustomErrorOrderImage
    },

    customPaymentFailedOrderImage() {
      return this.$store.getters.getCustomPaymentFailedOrderImage
    },

    acceptedHeaderMessage() {
      if (this.isServiceWithDelayedResponse) {
        return this.labels.order.requestUnderProcess[this.language];
      }

      if (this.deliveryType == 'SELF_SERVICE') {
        if (this.orderScheduled) {
          return this.labels.order.comeAndPickUpScheduledOrder[this.language];
        } else {
          return this.labels.order.comeAndPickUpSoon[this.language];
        }
      } else if (this.deliveryType == 'DINE_IN') {
        if (this.orderScheduled) {
          return this.labels.order.orderIsServedInTheDiningAreaOnSpecifiedTime[this.language];
        } else {
          return this.labels.order.orderIsServedInTheDiningArea[this.language];
        }
      } else {
        if (this.orderScheduled) {
          if (this.serviceType == 'REQUESTS') {
            return this.labels.order.requestReceivedHeader[this.language];
          } else {
            return this.labels.order.orderReceived[this.language];
          }
        } else {
          if (this.serviceType == 'REQUESTS') {
            return this.labels.order.requestAcceptedHeader[this.language];
          } else {
            return this.labels.order.comingSoon[this.language]
          }
        }
      }
    },

    acceptedFooterMessage() {

      if (this.isServiceWithDelayedResponse) {
        return this.labels.order.notifiedViaEmailOrPhoneWhenRequestReady[this.language];
      }

      let footerMessage = "";
      if (this.orderScheduled) {
        if (this.serviceType == 'REQUESTS') {
          footerMessage = this.labels.order.sheduledRequestAccepted[this.language];
        } else {
          footerMessage = this.labels.order.sheduledOrderAccepted[this.language];
        }
      } else {
        if (this.serviceType == 'REQUESTS') {
          footerMessage = this.labels.order.requestAcceptedSubtitle[this.language];
        } else {
          footerMessage = this.labels.order.orderAccepted[this.language];
        }
      }

      if (this.isEmailSentToUserAtOrderExecution && this.isSmsSentToUserAtOrderExecution) {
        footerMessage = footerMessage + " " + this.labels.order.notifiedViaEmailOrPhoneWhenOrderReady[this.language];
        if ((this.tableType == 'QR_SELF_SERVICE' || this.tableType == 'SELF_SERVICE_POS') && this.deliveryType != 'RENTALS') {
          footerMessage = footerMessage + " " + this.labels.cart.forPickup[this.language];
        }
      } else if (this.isEmailSentToUserAtOrderExecution && !this.isSmsSentToUserAtOrderExecution) {
        footerMessage = footerMessage + " " + this.labels.order.notifiedViaEmailWhenOrderReady[this.language];
        if ((this.tableType == 'QR_SELF_SERVICE' || this.tableType == 'SELF_SERVICE_POS') && this.deliveryType != 'RENTALS') {
          footerMessage = footerMessage + " " + this.labels.cart.forPickup[this.language];
        }
      } else if (!this.isEmailSentToUserAtOrderExecution && this.isSmsSentToUserAtOrderExecution) {
        footerMessage = footerMessage + " " + this.labels.order.notifiedViaPhoneWhenOrderReady[this.language];
        if ((this.tableType == 'QR_SELF_SERVICE' || this.tableType == 'SELF_SERVICE_POS') && this.deliveryType != 'RENTALS') {
          footerMessage = footerMessage + " " + this.labels.cart.forPickup[this.language];
        }
      }
      return footerMessage;
    },
  },
  data() {
    return {
      showInfo: false
    }
  },
  data() {
    return {
      loadingUnsub: false,
      unsubSuccess: undefined,
      showInfo: false,
      redirectTimeout: null
    }
  },
  methods: {
    onCloseClick: function () {
      this.$store.dispatch("closeOrderModal");
      this.$store.dispatch("setOrderId", undefined);
      this.$store.dispatch("setOrderDailyNumber", undefined);
      this.showInfo = false
      if (this.orderStatus == "ACCEPTED") {
        if (!this.hasUserOptedInForNewsletter && !this.unsubSuccess) {
          this.$store.dispatch("optUserInForNewsletter")
        }
        this.$store.dispatch("setOrderStatus", "");
        this.$store.dispatch("emptyCart");
        this.$store.dispatch("setOrderComment", "");
        this.unsubSuccess = undefined

        if (this.numberOfServices > 1 || this.redirectBackTo != null) {
          this.$router.push({
            name: "services",
            params: { tableId: this.redirectBackTo != null ? this.redirectBackTo : this.$router.currentRoute.params.tableId },
          });
        } else {
          this.$router.push({
            name: "menu",
            params: { tableId: this.$router.currentRoute.params.tableId },
          });
        }
      } else {
        this.unsubSuccess = undefined
        this.$store.dispatch("setOrderStatus", "");
      }
    },

    toggleInfo: function () {
      this.showInfo = !this.showInfo
    },

    unsubscribeFromNewsletter: async function () {
      this.loadingUnsub = true;

      let result = await UserApi.unsubscribeFromNewsletter()

      if (result.status == 200) {
        this.unsubSuccess = true
        this.$emit("unsub");
      } else {
        this.unsubSuccess = false
      }

      this.loadingUnsub = false;
    }
  },
  beforeDestroy() {
    clearTimeout(this.redirectTimeout);
    this.redirectTimeout = null;
  },
};
</script>

<style scoped>
#modal,
#accepted-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  min-height: 500px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  overflow: visible;

  opacity: 1;
  border-radius: 20px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 202;
}
@media screen and (max-width: 1285px) {
  #modal,
  #accepted-modal {
    width: 90vw;
    min-height: 450px;
  }
}

#modal::-webkit-scrollbar,
#accepted-modal::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#rejected-icon,
#congratulations-icon {
  width: 50%;
  height: auto;
}
@media screen and (max-width: 1285px) {
  #rejected-icon,
  #congratulations-icon {
    width: 80%;
    height: auto;
  }
}

#waiting-gif {
  height: 45vh;
}
@media screen and (max-width: 1285px) {
  #waiting-gif {
    height: 30vh;
  }
}
@media screen and (max-width: 1285px) and (max-height: 670px) {
  #waiting-gif {
    height: 35vh;
  }
}

#order-pending,
#order-rejected,
#order-error,
#order-accepted,
#self-service-order-accepted {
  text-align: center;
  width: 90%;
  margin-top: 30px;
}

#order-daily-number {
  font-weight: 700 !important;
  font-size: 28px !important;
  color: #198b4a;
}

#order-pending > h2,
#order-accepted > h2,
#self-service-order-accepted > h2 {
  color: #003340;
}

#order-pending > h4,
#order-rejected > h4,
#order-error > h4,
#order-accepted > h4 {
  color: gray;
  font-size: 22px;
}

@media screen and (max-width: 1285px) {
  #order-pending > h4,
  #order-rejected > h4,
  #order-error > h4,
  #order-accepted > h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 350px) {
  #order-pending > h4,
  #order-rejected > h4,
  #order-error > h4,
  #order-accepted > h4 {
    font-size: 14px;
  }
}

#order-rejected > h2 {
  color: #dc4a40;
}

#order-error > h2 {
  color: #dc4a40;
}

@media screen and (max-width: 1285px) {
  #order-rejected > h2,
  #order-pending > h2 {
    font-size: 22px;
  }

  #order-error > h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 350px) {
  #order-rejected > h2,
  #order-pending > h2 {
    font-size: 20px;
  }

  #order-error > h2 {
    font-size: 18px;
  }
}

#waiting-div,
#congratulations-div,
#rejected-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#warning,
#help-coming,
#accepted-order-footer {
  color: #757575;
  display: flex;
  justify-content: center;
  width: 90%;
  text-align: center;
  margin-bottom: 26px;
}

.footer-message {
  font-size: 18px;
  font-weight: 600;
}

.bolderFooter {
  color: #5b5b5b !important;
  font-weight: 700;
}

#exclamation-sign {
  margin-right: 0px;
  font-size: 17px;
  color: #f3a800;
}

@media screen and (max-width: 1285px) {
  .footer-message {
    font-size: 16px;
  }

  .smallerAcceptedHeader {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
}

#close-modal {
  position: absolute;
  bottom: 14px;
  right: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: gray;
  cursor: pointer;
}

#personal-data-info {
  position: absolute;
  bottom: 10px;
  left: 12px;
  z-index: 203;
}

#info-icon {
  color: gray;
  cursor: pointer;
}

#info-message-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center !important;
  transform: translate(100%, -30%);
  width: 80vw;
  max-width: 400px;

  padding: 10px;
  min-width: 20vw;
  font-size: 12px;
  background: white;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.23), 3px 3px 4px rgba(0, 0, 0, 0.36);
  border-radius: 16px;
  z-index: 202;
}
@media screen and (max-width: 600px) {
  #info-message-container {
    transform: translate(95%, -25%);
  }
}

#info-message-container > p {
  margin: 0;
  margin-bottom: 10px;
  z-index: 202;
}

#unsub-button-and-result {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

#unsub-newsletter {
  border: 1px solid #f30;
  color: #f30;
  font-size: 600;
  min-width: 33%;
  box-shadow: unset;
  padding: 0.25rem 0.5rem;
}

#new-order-button {
  border: 1px solid #198b4a;
  color: #ffffff;
  background-color: #198b4a;
  font-weight: 600;
  min-width: 50%;
  box-shadow: unset;
  padding: 0.25rem 0.5rem;
  min-height: 40px;
}

#unsub-error {
  color: #f30;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 0;
}

#unsub-success {
  color: #198b4a;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 0;
}
</style>