<template>
  <div id="cart-item" @click="onEdit">
    <div id="name-and-quantity">
      <div id="item" v-if="!isDealItem">
        <div class="quantity-container">
          <div class="quantity-number">{{ item.quantity }}</div>
        </div>
        <h6 class="item-name">{{ getItemName(item.item) }}</h6>
      </div>
      <div v-else id="item">
        <div class="quantity-container">
          <div class="quantity-number">1</div>
        </div>
        <h6 class="item-name">
          <span class="deal-header">
            {{ labels.categories.dealsCategory[language] }}
            <br />
          </span>
          {{ getItemName(item.item) }}
        </h6>
      </div>
      <div id="options">
        <font-awesome-icon id="pencil" icon="pencil-alt" :style="{ color: '#3b3838 ' }" />
        <font-awesome-icon
          @click="onDelete($event)"
          id="times"
          icon="times"
          :style="{ color: '#f30' }"
        />
      </div>
    </div>
    <div id="subselections">
      <h6 v-html="subselections"></h6>
    </div>
    <div
      v-if="hasSubselections && noComment && !isInHappyHour && isPriceable"
      :class="{ shiftUp: hasSubselections && noComment }"
      id="cost-container-no-comment"
    >
      <h6 :class="{ croseedOut: isInHappyHour }" id="cost">
        {{ serviceHasMemembershipEnabled && hasMembershipPrice && userHasMembership ? membershipPriceForQuantity: itemPriceForQuantity }}€
        <font-awesome-icon
          v-if="(membershipPricesAreHidden && hasMembershipPrice) || (serviceHasMemembershipEnabled && hasMembershipPrice && userHasMembership)"
          icon="home"
          style="margin-left: 4px;"
        />
      </h6>
      <h6 v-if="isInHappyHour" class="discounted-cost">
        {{ happyHourItemPriceForQuantity }}€
        <font-awesome-icon style="margin-left: 5px" :icon="['far', 'smile']" />
      </h6>
    </div>
    <div v-else id="comment-and-cost">
      <div id="comment">
        <h6 v-if="item.comment">
          <span
            style="color: #5d5d5d; font-weight: 600; margin-right: 2px;"
          >{{ labels.cart.cartItemCommentLabel[language] }}:</span>
          {{ item.comment }}
        </h6>
      </div>
      <div
        :class="{ shiftUp: hasSubselections && noComment }"
        id="cost-container"
        v-if="isPriceable"
      >
        <h6 :class="{ croseedOut: isInHappyHour }" id="cost">
          {{ serviceHasMemembershipEnabled && hasMembershipPrice && userHasMembership ? membershipPriceForQuantity: itemPriceForQuantity }}€
          <font-awesome-icon
            v-if="(membershipPricesAreHidden && hasMembershipPrice) || (serviceHasMemembershipEnabled && hasMembershipPrice && userHasMembership)"
            icon="home"
            style="margin-left: 4px;"
          />
        </h6>
        <h6 v-if="isInHappyHour" class="discounted-cost">
          {{ happyHourItemPriceForQuantity }}€
          <font-awesome-icon style="margin-left: 5px" :icon="['far', 'smile']" />
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartItem",
  components: {},
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },

    selectedExtraLanguage() {
      return this.$store.getters.getSelectedExtraLanguage;
    },

    subselectionsPrice() {
      let subselectionsPrice = 0;
      this.item.item.subselections.forEach((subselection) => {
        subselection.options.forEach((option) => {
          if (option.isSelected) {
            subselectionsPrice += option.price;
          }
        });
      });

      return subselectionsPrice;
    },

    membershipPriceForQuantity() {
      return (
        (this.item.item.membershipPrice + this.subselectionsPrice) *
        this.item.quantity
      ).toFixed(2);
    },

    itemPriceForQuantity() {
      return (
        (this.item.item.price + this.subselectionsPrice) *
        this.item.quantity
      ).toFixed(2);
    },

    hasMembershipPrice() {
      return this.item.item.memberPriceEnabled && this.item.item.price != this.item.item.membershipPrice;
    },

    isMeasurable() {
      return this.item.item.measurable;
    },

    isPriceable() {
      return this.item.item.priceable;
    },

    happyHourItemPriceForQuantity() {
      return (
        (this.itemPriceForQuantity * (100 - this.happyHourDiscountPercentage)) /
        100
      ).toFixed(2);
    },

    happyHourDiscountPercentage() {
      return this.$store.getters.getHappyHourDiscountPercentage;
    },

    isInHappyHour() {
      if (this.currentGreekDate == '' || this.currentGreekDate == undefined) {
        return false;
      }
      return this.$store.getters.isInHappyHour(this.currentGreekDate);
    },

    isDealItem() {
      return this.item.item.productCategoryName == "Deals";
    },

    subselections() {
      if (this.item.item.subselections) {
        let subselections = "";
        this.item.item.subselections.forEach((subselection) => {
          if (subselection.options.some((option) => option.isSelected)) {
            subselections += `<span style="color: #5d5d5d; font-weight: 600;">${subselection.names.translation[this.language].name
              }:</span> `;
            subselection.options.forEach((option) => {
              if (option.isSelected) {
                subselections += `${option.names.translation[this.language].name
                  }, `;
              }
            });
            if (subselections.slice(-2) == ", ") {
              subselections = subselections.slice(0, -2);
            }
            subselections += "\n";
          }
        });

        return subselections;
      }
    },

    hasSubselections() {
      return this.subselections.trim() != "";
    },

    noComment() {
      return this.item.comment.trim() == "";
    },

    currentGreekDate() {
      return this.$store.getters.getCurrentGreekDate
    }
  },
  props: ["item", "index", "membershipPricesAreHidden", "serviceHasMemembershipEnabled", "userHasMembership"],
  methods: {
    getItemName(item) {
      if (this.selectedExtraLanguage != null && item.names.translation[this.selectedExtraLanguage] && item.names.translation[this.selectedExtraLanguage].name != null && item.names.translation[this.selectedExtraLanguage].name.trim().length != 0) {
        return item.names.translation[this.selectedExtraLanguage].name;
      }
      return item.names.translation[this.language].name;
    },

    onDelete: function (e) {
      e.stopPropagation();
      this.$store.dispatch("removeFromCart", this.item);
    },

    scrollDownPixels(pixels) {
      try {
        window.scrollTo(0, window.scrollY + pixels);
      } catch (error) {
        console.log("Could not scroll");
      }
    },

    onEdit: function () {
      //this is made for the pull to refresh not to work
      //on the webview while the modal is open if the screen was at the top
      //the pull to refresh was very sensitive
      this.scrollDownPixels(4)
      this.$store.dispatch("setIsItemEditModalTrue");
      this.$store.dispatch("setSelectedItem", this.item.item);
      this.$store.dispatch("setIndexOfItemInCart", this.index);
      this.$store.dispatch("setItemModalQuantity", this.item.quantity);
      this.$store.dispatch("setItemModalComment", this.item.comment);
      this.$store.dispatch("setItemModalId", this.item.id);

      let modal = document.getElementById("modal");
      if (modal != null) {
        modal.scrollTop = 0;
      }

      this.$store.dispatch("openItemModal");
    },
  },
};
</script>

<style scoped>
#cart-item {
  cursor: pointer;
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  color: black;
}

#name-and-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#comment {
  display: flex;
  align-items: center;
  max-width: 70%;
  overflow-wrap: anywhere;
  margin-left: 34px;
}

#comment > h6,
#subselections > h6 {
  color: #7b7b7b;
  font-weight: 500;
  font-size: 13px;
}

#subselections {
  width: 86%;
  margin-left: 34px;
}

.deal-header {
  font-size: 15px;
  font-weight: 600;
  color: #198b4a;
}

#subselections > h6 {
  white-space: pre-wrap;
}

#item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 86%;
  padding-right: 5%;
  margin-bottom: 4px;
}

#item > h6 {
  font-size: 14px;
}

#item-quantity {
  font-size: 14px;
}

.quantity-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 33px;
}

.quantity-number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 0.76rem;
  font-weight: 700;
  border-radius: 4px;
  background-color: rgb(236, 236, 236);
  padding: 0.25rem 0.25rem 0.125rem;
  min-width: 25px;
  cursor: pointer;
}

.item-name {
  margin-top: 2px;
  margin-bottom: 0px;
}

.croseedOut {
  text-decoration: line-through #696969;
}

#quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 0.5vw;
}

#plus,
#minus {
  width: 1.3vw;
  height: 1.3vw;
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

#current-quantity {
  margin-left: 0.5vw;
}

#plus {
  margin-left: 0.5vw;
}

#times {
  cursor: pointer;
  margin-bottom: 1vh;
  width: 14px;
  height: 14px;
}

#pencil {
  cursor: pointer;
  margin-bottom: 1vh;
  margin-right: 1.2vw;
  width: 14px;
  height: 14px;
}

#options {
  display: flex;
  flex-direction: row;
}

#comment-and-cost {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

#cost-container {
  display: flex;
}

#cost-container-no-comment {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0vh;
}

#cost,
.discounted-cost {
  color: gray;
  font-weight: 500;
  font-size: 13px;
}

.discounted-cost {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #198b4a;
  font-weight: 700 !important;
}

/* responsive shit */

@media screen and (max-width: 1285px) {
  #pencil {
    margin-right: 5vw;
    width: 12px;
    height: 12px;
  }

  #times {
    width: 15px;
    height: 15px;
  }

  #subselections {
    width: 76%;
  }
}

@media screen and (max-width: 753px) {
  #cost,
  .discounted-cost {
    font-size: 12px;
  }
}
</style>