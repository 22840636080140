import axios from "axios";

export default {
  async getStores(area) {
    return axios
      .get(`/restaurant/area/like?areaName=${area}`)
      .then(response => { return response.data })
      .catch(error => console.log(error.response))
  },

  async getStoreReviews(storeId, page) {
    return axios
      .get(`/review/store/${storeId}?page=${page}&resultsPerPage=15`)
      .then(response => { return response.data })
      .catch(error => console.log(error.response))
  },

  async getStoreByTableIdAndService(tableId, service) {
    let result = await axios
      .get(`/store?tableId=${tableId}&departmentName=${service}`)
      .catch((error) => console.log(error.response));

    return result.data;
  },

  async getStoreById(id) {
    return axios
      .get(`/store/${id}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getTable(tableId) {
    return axios
      .get(`/restaurant/table?tableId=${tableId}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getOrderLimit(restaurantId) {
    return axios
      .get(`/restaurant/order-limit?restaurantId=${restaurantId}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getListOfRooms(storeId, isStoreSplitInSections, tableSection) {
    let sectionParameter = ""

    if (isStoreSplitInSections) {
      sectionParameter = `&section=${tableSection}`
    }

    return axios
      .get(`/store/hotel-rooms?storeId=${storeId}${sectionParameter}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getListOfAvailableTables(storeId, fetchTablesPerSection, restaurantId, tableType, tableSection) {

    let sectionParameter = ""

    if (fetchTablesPerSection) {
      sectionParameter = `&section=${tableSection}`
    }

    return axios
      .get(`/store/tables?storeId=${storeId}&restaurantId=${restaurantId}&type=${tableType}${sectionParameter}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getAllAvailableTablesForRestaurant(storeId, restaurantId) {
    return axios
      .get(`/store/all-tables?storeId=${storeId}&restaurantId=${restaurantId}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getAllAvailableTablesAndPackagesOfStore(storeId) {
    return axios
      .get(`/store/tables-and-packages?storeId=${storeId}`)
      .then(response => { return response.data })
      .catch((error) => console.log(error.response));
  },

  async getRestaurantsWithTableOpenings(storeId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/restaurant/of-type-table-opening?storeId=${storeId}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getRestaurantsWithRoomOpenings(storeId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/restaurant/of-type-room-opening?storeId=${storeId}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getTableSections(storeId) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/store/sections/tables?storeId=${storeId}`, {
        headers: {
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getHotelRoomSections(storeId) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/store/sections/hotel-rooms?storeId=${storeId}`, {
        headers: {
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getTableOpeningsForRestaurant(restaurantId, tableTypes, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/table-opening/v2/fetch-open?restaurantIds=${restaurantId}&tableTypes=${tableTypes}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async getTablesOfRestaurants(storeId, restaurantIds, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/store/tables-of-restaurants?storeId=${storeId}&restaurantIds=${restaurantIds}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async closeTableOpeningsForRestaurant(restaurantId, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/close-table?restaurantId=${restaurantId}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async transferTableOpening(tableOpeningId, tableToTransferId, restaurantId, restaurantIdToTransferTable, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/waiter/tranfer-table/${tableOpeningId}?tableToTransferId=${tableToTransferId}&restaurantId=${restaurantId}&restaurantIdToTransferTable=${restaurantIdToTransferTable}`, null, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async payPartialBeforeClose(restaurantIds, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/pay-partial-before-close?restaurantIds=${restaurantIds}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },


  async chargeToRoom(restaurantIds, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/charge-to-room?restaurantIds=${restaurantIds}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async partialTransfer(restaurantIds, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/partial-transfer?restaurantIds=${restaurantIds}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async cancelProducts(restaurantIds, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/cancel-products?restaurantIds=${restaurantIds}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async treatProducts(restaurantIds, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/treat-products?restaurantIds=${restaurantIds}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async reOrder(restaurantIds, payload, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/table-opening/repeat-order?restaurantIds=${restaurantIds}`, payload, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },
};
