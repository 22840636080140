<template>
  <div v-if="table" id="table-name-container">
    <div id="label-and-name" style="margin-bottom: 8px;" v-if="sections.length>0">
      <div id="label">
        <h6>{{labels.tableOpenings.areaSection[language]}}</h6>
        <select v-model="selectedSection">
          <option value="ALL">Όλα</option>
          <option v-for="(section, index) in sections" :key="index" :value="section">{{section}}</option>
          <option :value="null">Υπόλοιπα</option>
        </select>
      </div>
    </div>
    <div id="label-and-name">
      <div id="label">
        <h6>{{ tableType }}:</h6>
        <select @change="onTableSelect" v-if="tables" required v-model="selectedTableId">
          <option selected disabled :value="null">{{ beforeSelectTableText }}</option>
          <option
            v-for="availableTable in tables"
            :key="availableTable.id"
            :value="availableTable.id"
          >{{ availableTable.tableNumber }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableName",
  components: {},
  props: ["table", "sections"],
  data() {
    return {
      selectedTableId: null,
      selectedSection: "ALL"
    }
  },
  watch: {
    tables(newVal, oldVal) {
      // TODO maybe when "newVal && newVal.length == 1" then auto select the first table
      if (newVal && newVal.length == 1) {
        let selectedTable = newVal[0];
        this.selectedTableId = selectedTable.id;
        this.$emit("table-select");
        this.$store.dispatch("setSelectedTable", selectedTable);
      } else if (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined) {
        let selectedTable = newVal.find(table => table.id == this.$route.query.tableId);
        if (selectedTable) {
          this.selectedTableId = selectedTable.id;
          this.$emit("table-select");
          this.$store.dispatch("setSelectedTable", selectedTable);
        }
      }
    },
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },

    tableType() {
      return this.table.type == "HOTEL_ROOM"
        ? this.labels.table.room[this.language]
        : this.table.type == "BEACH_CHAIR" || this.table.type == "UMBRELLA_AT_HOTEL"
          ? this.labels.table.beachChair[this.language]
          : (this.table.type == "QR_SELF_SERVICE" || this.table.type == "SELF_SERVICE_POS" || this.table.type == "DELIVERY_TABLE")
            ? this.labels.table.spot[this.language]
            : this.labels.table.table[this.language];
    },

    tables() {
      if (this.selectedSection == "ALL") {
        if (this.sections.length > 0) {
          this.selectedTableId = null;
          this.$emit("table-select");
          this.$store.dispatch("setSelectedTable", null);
        }
        return this.$store.getters.getAvailableTables;
      }

      if (this.sections.length > 0) {
        this.selectedTableId = null;
        this.$emit("table-select");
        this.$store.dispatch("setSelectedTable", null);
      }
      return this.$store.getters.getAvailableTables.filter(table => table.familySection == this.selectedSection);
    },

    beforeSelectTableText() {
      return this.table.type == "HOTEL_ROOM"
        ? this.labels.table.chooseYourRoom[this.language]
        : this.table.type == "BEACH_CHAIR" || this.table.type == "UMBRELLA_AT_HOTEL"
          ? this.labels.table.chooseYourBeachChair[this.language]
          : (this.table.type == "QR_SELF_SERVICE" || this.table.type == "SELF_SERVICE_POS" || this.table.type == "DELIVERY_TABLE")
            ? this.labels.table.chooseYourSpot[this.language]
            : this.labels.table.chooseYourTable[this.language];
    },

  },
  methods: {
    onTableSelect() {
      let selectedTable = this.tables.find(table => table.id == this.selectedTableId);
      this.$emit("table-select");
      this.$store.dispatch("setSelectedTable", selectedTable);
    }
  }
};
</script>

<style scoped>
#table-name-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

#label-and-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

#label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#label > h6 {
  font-size: 14px;
  font-weight: 600;
}

#label > h6 > span {
  cursor: pointer;
  color: #198b4a;
  /* color: #ed5005;  */
  font-weight: bold;
  margin-left: 5px;
  font-size: 14px;
}

#label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

select {
  display: flex;
  align-self: center;
  justify-self: center;
  -webkit-appearance: auto !important;
  box-shadow: inset 0px 0px 2px rgba(116, 135, 150, 0.2),
    inset 1px 1px 2px rgba(116, 135, 150, 0.36);
  border-radius: 6px;
  margin-bottom: 0.5rem;
  margin-left: 7px;
  padding: 2px;
  color: #198b4a !important;
  font-weight: bold;
  background: #f7f7f7;
}
@media screen and (max-width: 800px) {
  select {
    font-size: 14.5px;
  }
}
@media screen and (max-width: 300px) {
  select {
    font-size: 12px;
  }
}

option {
  color: #198b4a !important;
  font-weight: 600;
}
</style>